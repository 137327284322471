<template>
    <div>
        <Alerts :_success="success" :_error="error"></Alerts>
        <!--Filters-->
        <div v-if="respondents.length !== 0" class="row g-2 px-4 mt-4">
            <div class="mb-1 mb-md-3 col-lg-3">
                <b-form-input v-model="filter_search" class="form-control" placeholder="Search by Respondent..."
                              type="search" autofocus @change="submitSearch()"/>
            </div>
            <div class="mb-3 mb-md-3 col-lg-3">
                <b-select class="form-select" v-model="status">
                    <option value="">-- Filter by status --</option>
                    <option value="approved">Approved</option>
                    <option value="flagged">Flagged</option>
                    <option value="declined">Declined</option>
                </b-select>
            </div>
        </div>

        <div v-if="wait && survey.respondents_count !== 0" class="alert alert-info">
            <h6 class="mb-1"><span class="font-weight-semibold">Just a second...</span></h6>
            <p class="mb-0">Retrieving your responses.</p>
        </div>

        <!-- Responses content -->
        <div class="responses-content">
            <div class="responses-wrapper">
                <div class="responses-data">

                    <!-- Respondents list - dropdown (visible on mobile) -->
                    <div class="respondents-list-dropdown">
                        <label class="form-label">Select respondent</label>
                        <b-form-select class="form-select" v-model="selected_res" @click.prevent="getResponses()">
                            <option v-for="res in respondents" :key="res.id" :value="res"
                                    :class="res.id == respondent.id ? 'active' : ''">
                                {{ res.user.name }}<br/>
                                Date Filled - {{ moment(res.start_time).format("DD/MM/YYYY, HH:mm A") }}
                            </option>
                        </b-form-select>
                    </div>

                    <!-- Respondents list -->

                    <div class="respondents-list">
                        <!-- Respondent -->
                        <div v-for="res in respondentsLoaded" :key="res.id" class="respondent">
                            <div @click.prevent="setRespondent(res)" style="background: #f9f9f9">
                                <a class="respondent-inner" :class="res.id == respondent.id ? 'selected' : ''">
                                    <span class="user-id">
                                        {{ res.user.name }}
                                        <font-awesome-icon v-if="res.status == 'approve'" icon="check"
                                                           class="ms-1 success"
                                                           title="This response has been marked as Approved"/>
                                        <font-awesome-icon v-if="res.status == 'flagged'" icon="flag"
                                                           class="ms-1 warning"
                                                           title="This response has been marked as flagged"/>
                                        <font-awesome-icon v-if="res.status == 'declined'" icon="ban"
                                                           class="ms-1 danger"
                                                           title="This response has been declined"/>
                                    </span>
                                    <!--                                    <span class="timestamp">-->
                                    <!--                                                    {{ moment(res.created_at).format("DD/MM/YYYY, HH:mm A") }}-->
                                    <!--                                                </span>-->
                                    <span v-if="survey.collect_geo" class="gps">
                                        <font-awesome-icon :icon="['fas', 'map-marker-alt']"/>
                                        <template v-if="res.geo">{{ res.geo }} <span
                                            class="info sm">Auto-Generated</span></template>
                                        <template v-else class="text-muted">{{ lat }}, {{ lon }}</template>
                                    </span>
                                    <span v-if="res.start_time && res.end_time" class="duration">
                                        <font-awesome-icon :icon="['fas', 'stopwatch']"/>
                                        Date Filled - {{
                                            moment(res.start_time).format("DD/MM/YYYY, HH:mm A")
                                        }}<br/>
                                        <font-awesome-icon :icon="['fas', 'stopwatch']"/>
                                        Date Submitted - {{
                                            moment(res.end_time).format("DD/MM/YYYY, HH:mm A")
                                        }}
                                    </span>
                                    <span v-else class="duration">
                                        <font-awesome-icon :icon="['fas', 'stopwatch']"/>
                                        Date Filled - {{
                                            moment(res.created_at).format("DD/MM/YYYY, HH:mm A")
                                        }}<br/>
                                        <font-awesome-icon :icon="['fas', 'stopwatch']"/>
                                        Date Submitted - {{
                                            moment(res.created_at).format("DD/MM/YYYY, HH:mm A")
                                        }}
                                    </span>
                                </a>
                            </div>

                        </div>

                        <div v-if="respondents.length > 5" class="respondents-list-nav">
                            <button class="custom-btn custom-btn-xs custom-btn-outline custom-btn-full"
                                    v-if="respondents.length !== respondentsLoaded.length" @click="loadMore">Load
                                More...
                            </button>
                            <span v-else class="text-muted">You've reached the end!</span>
                        </div>

                    </div>

                    <!-- Answers -->
                    <div class="response-container">
                        <div v-if="respondent" class="response">
                            <!-- Response header -->
                            <div class="response-header">
                                <div>
                                    <h5 class="mb-2">Response Details</h5>
                                    <ul>
                                        <li>
                                            <span id="r-code-tooltip">Response ID:</span>
                                            <b-tooltip target="r-code-tooltip">This is a unique ID assigned to every
                                                response
                                            </b-tooltip>
                                            {{ respondent.code }}
                                        </li>
                                        <li>
                                            <span id="filled-tooltip">Filled:</span>
                                            <b-tooltip target="filled-tooltip">This is the time the response was filled
                                            </b-tooltip>
                                            <span v-if="respondent.start_time" class="timestamp">
                                                {{ moment(respondent.start_time).format("DD/MM/YYYY, HH:mm A") }}
                                            </span>
                                            <span v-else class="timestamp">
                                                {{ moment(respondent.created_at).format("DD/MM/YYYY, HH:mm A") }}
                                            </span>
                                        </li>
                                        <li>
                                            <span id="uploaded-tooltip">Uploaded:</span>
                                            <b-tooltip target="uploaded-tooltip">This is the time the response was
                                                uploaded
                                            </b-tooltip>
                                            <span class="timestamp">
                                                {{ moment(respondent.created_at).format("DD/MM/YYYY, HH:mm A") }}
                                            </span>
                                        </li>
                                        <!--                                      <li>-->
                                        <!--                                        <span id="duration-tooltip">Upload:</span>-->
                                        <!--                                        <b-tooltip target="duration-tooltip">This is the time taken to upload the Survey.-->
                                        <!--                                        </b-tooltip>-->
                                        <!--                                        {{ moment(respondent.created_at).format("Do:dddd:HH:mm")  }}-->
                                        <!--                                      </li>-->
                                    </ul>
                                </div>

                                <div v-if="respondent" class="response-header-actions">
                                    <a class="approve-response" href="#"
                                       v-if="survey.response_approve && respondent.status !== 'approve'"
                                       @click.prevent="actOnResponse('approve')" title="Approve this response">
                                        <font-awesome-icon icon="check"/>
                                    </a>
                                    <a class="flag-response" href="#"
                                       v-if="survey.response_flag && respondent.status !== 'flagged'"
                                       @click.prevent="flag()" title="Flag this response">
                                        <font-awesome-icon icon="flag"/>
                                    </a>
                                    <a class="trash-response" href="#"
                                       v-if="survey.response_decline && respondent.status !== 'declined'"
                                       @click.prevent="actOnResponse('decline')" title="Decline this response">
                                        <font-awesome-icon icon="trash"/>
                                    </a>
                                    <a class="lang" v-if="respondent.lang" @click.prevent :title="respondent.lang == 'en' ?
                                        'English' :
                                        respondent.lang == 'fr' ?
                                            'French' :
                                            respondent.lang == 'swa' ?
                                                'Swahili' : ''">{{ respondent.lang }}
                                    </a>
                                </div>

                            </div>
                            <div class="my-2" v-if="respondent.status == 'approve'">
                            </div>

                            <!-- Answers Container -->
                            <div v-if="respondent">
                                <div class="answers-container">
                                    <div class="my-2 alert alert-success" v-if="respondent.status == 'approve'">
                                        <strong>This response has been Manually approved.</strong><br>
                                        <template v-if="respondent.memo !== 'null'">
                                            <!--                                      <strong>Comments:</strong> {{ respondent.memo }}-->
                                        </template>
                                        <div class="alert-btn">
                                            <button class="custom-btn custom-btn-outline custom-btn-xs"
                                                    @click="unApprove()"
                                                    title="unApprove">
                                                <font-awesome-icon icon="undo"/>
                                                Undo
                                            </button>
                                        </div>
                                    </div>

                                    <div class="my-2 alert alert-warning" v-if="respondent.status == 'flagged'">
                                        <strong>This response has been flagged.</strong><br>
                                        <template v-if="respondent.memo !== 'null'">
                                            <strong>Comments:</strong> {{ respondent.memo }}
                                        </template>
                                        <div class="alert-btn">
                                            <button class="custom-btn custom-btn-outline custom-btn-xs"
                                                    @click="unflag()"
                                                    title="Remove flag">
                                                <font-awesome-icon icon="undo"/>
                                                Undo
                                            </button>
                                        </div>
                                    </div>

                                    <div v-else-if="respondent.status == 'declined'" class="my-2 alert alert-danger">
                                        <strong>This response has been declined.</strong>
                                        <div class="alert-btn">
                                            <button class="custom-btn custom-btn-outline custom-btn-xs"
                                                    @click="actOnResponse('undecline')">
                                                <font-awesome-icon icon="undo"/>
                                                Undo
                                            </button>
                                        </div>
                                    </div>

                                    <div class="answer-item" v-for="(a, index) in respondent.json" :key="index">

                                        <!-- Question Title -->
                                        <div class="question-title">
                                            <strong :title="a.code">{{ a.question }}</strong>
                                            <!--                                          <strong>{{ a.code }}.</strong>{{ a.question }}-->
                                        </div>

                                        <div class="answer-content">
                                            <p class="mb-0">
                                                <template v-if="a.type === 'checkbox'">
                                                    <ul class="list-group">
                                                        <li class="list-group-item text-primary">
                                                            {{ a.answer === true ? 'Yes' : 'No' }}
                                                        </li>
                                                    </ul>
                                                </template>
                                                <template v-if="Array.isArray(a.answer)">
                                                    <ul class="list-group">
                                                        <li class="list-group-item text-primary"
                                                            v-for="item in a.answer"
                                                            :key="item">
                                                            {{ item }}
                                                        </li>
                                                    </ul>
                                                </template>
                                                <template v-else-if="a.other">
                                                    {{ a.answer }} - {{ a.other }}
                                                </template>
                                                <template v-else class="d-block">
                                                    <ul class="list-group">
                                                        <li :key="a.answer" v-if="typeof a.answer === 'string'"
                                                            class="list-group-item text-primary">
                                                            {{ a.answer }}
                                                        </li>
                                                        <li v-else-if="Array.isArray(a.answer)" :key="a"
                                                            class="list-group-item">
                                                            <ul>
                                                                <li v-for="answerItem in a.answer" :key="answerItem">
                                                                    {{ answerItem }}
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li class="list-group-item"
                                                            v-if="a.additional_info && !Array.isArray(a.additional_info)">
                                                            {{ a.additional_info }}
                                                        </li>
                                                        <li v-else v-for="x in a.additional_info" :key="x.key"
                                                            class="list-group-item">
                                                            <span v-for="answerItem in a.answer.split(',')"
                                                                  :key="answerItem.key">
                                                                {{ answerItem }} - {{ x.value }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alerts from "../../Helpers/Alerts"


export default {
    name: "SurveyRespondentsColumn",
    components: {Alerts},
    props: ['code', 'survey', 'response_code'],
    data() {
        return {
            // survey: {},
            respondents: [],
            respondent: '',
            selected_res: '',
            filter_respondent: '',
            questions: [],
            selected: [],
            end_time: '',
            start_time: '',
            duration: '',
            lat: '',
            lon: '',

            length: 5,

            columns: true,
            table: false,
            maps: false,

            status: '',
            filter_search: '',
            url: '',
            error: ''
        }
    },
    watch: {
        selected_res() {
            this.respondent = this.selected_res
        },
        status() {
            this.loadRespondents()
        },
        filter_respondent() {
            this.loadRespondents()
        },
        filter_search() {
            this.loadRespondents()
            this.submitQuestionSearch()
        },
        selected: function () {
            this.url = `/api/export/csv/${this.code}` + this.selected;
        },
    },
    mounted() {
        this.loadRespondents()
        this.autoLocation()
    },
    computed: {
        respondentsLoaded: function () {
            return this.respondents.slice(0, this.length)
        },
        // selectAll: {
        //     get: function () {
        //         return this.respondents ? this.selected.length == this.respondents.length : false;
        //     },
        //     set: function (value) {
        //         var selected = [];
        //
        //         if (value) {
        //             this.respondents.forEach(function (res) {
        //                 selected.push(res);
        //             });
        //         }
        //         this.selected = selected;
        //     }
        // }
    },
    methods: {
        loadMore() {
            if (this.length > this.respondents.length) return
            this.length = this.length + 3
        },
        loadRespondents: function () {
            this.clear()
            this.wait = true
            this.success = "Loading respondents data, please wait..."

            this.$http.get(`/api/profile/survey/${this.code}/respondents?status=` + this.status + '&search=' + this.filter_search).then(response => {
                this.respondents = response.data.data
                // this.questions = response.data.data.map(a => a.json.map(b => b.question))
                console.log(response.data.data)
                if (this.response_code) {
                    this.respondent = this.respondents.find(a => a.code == this.response_code)
                } else {
                    this.respondent = response.data.data[0]
                }

            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.success = ''
                this.wait = false
            })
        },

        submitSearch() {
            this.$http.get('/api/search/questions?search=' + this.filter_search).then(response => {
                this.respondents = response.data.data
                this.respondent = response.data.data[0]
            }).catch(error => {
                this.showError(error)
            })
        },
        getStatus(response) {
            if (response == 'flagged') {
                return 'text-danger'
            } else if (response === 'declined') {
                return 'text-warning'
            } else if (response === 'approve') {
                return 'text-success'
            } else {
                return 'text-success'
            }
        },
        autoLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else {
                this.error = "Geolocation is not supported.";

            }
        },
        showPosition: function (position) {
            this.lat = position.coords.latitude;
            this.lon = position.coords.longitude;
        },

        unApprove() {
            this.clear()
            this.$http.post(`/api/response/${this.respondent.id}/approve`).then(() => {
                this.loadRespondents()
                this.$toaster.success('Response unApproved')
            }).catch(error => {
                this.showError(error)
            })
        },
        unflag() {
            this.clear()
            this.$http.post(`/api/response/${this.respondent.id}/flag?reason=`).then(() => {
                this.loadRespondents()
                //this.success = 'Response unflagged successfully'
                this.$toaster.success('Response unflagged')
            }).catch(error => {
                this.showError(error)
            })
        },
        flag() {
            let reason = prompt(`Provide reason why you are flagging response from ${this.respondent.user.name}`, '');
            this.$http.post(`/api/response/${this.respondent.id}/flag?reason=${reason}`).then(response => {
                this.respondent = response.data.data
            }).catch(error => {
                this.showError(error)
            })
        },
        undecline() {
            this.$http.post(`/api/response/${this.respondent.id}/decline`).then(() => {
                this.loadRespondents()
                //this.success = 'Response accepted successfully'
                this.$toaster.success('Response approved')
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
                this.success = ''
            })
        },
        actOnResponse(action) {
            this.clear()
            if (confirm(`Do you want to ${action} this response`)) {
                this.$http.post(`/api/response/${this.respondent.id}/${action}`).then(response => {
                    this.loadRespondents()
                    this.respondent = response.data.data
                }).catch(error => {
                    this.showError(error)
                })
            }
        },
        calculateDuration: function () {
            return parseInt(this.end_time) - parseInt(this.start_time);
        },
        setRespondent(res) {
            this.respondent = res
        },
        getResponses(res) {
            this.respondent = res
        },
        setColumns() {
            this.columns = true
            this.table = false
            this.maps = false
        },
        setTable() {
            this.table = true
            this.columns = false
            this.maps = false
        },
        setMaps() {
            this.columns = false
            this.table = false
            this.maps = true
        }
    }
}
</script>
<style scoped></style>

<style lang="css" scoped>
.approve-response {
    border-color: #2ca02c;
    color: #2ca02c;
}
</style>