<template>
    <div>
        <div v-if="survey" class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">


            <h4 class="font-weight-semibold">{{ survey.title }}</h4>


            <!-- Responses header -->
            <div class="responses-header mt-3">
                <div class="response-count">
                    <span>Views: <strong>{{ stats.views }}</strong></span>
                    <span>Responses: <strong>{{ stats.responses }}</strong></span>
                    <span>Questions: <strong>{{ stats.questions }}</strong></span>
                </div>
                <div class="responses-refresh">
                    <!--  Menu bar-->
                    <div class="stat-card-actions">
                        <ul class="nav">
                            <li  class="me-1">
                                <button :class="columns ? 'active' : ''"
                                        class="custom-btn custom-btn-outline custom-btn-xs"
                                        title="Switch to list view" @click="setColumns()">
                                    <font-awesome-icon icon="list"/>
                                    <span v-if="table"  class="d-none d-lg-inline-block">Switch to List View</span>
                                    <span class="d-lg-none d-inline-block">List View</span>
                                </button>
                            </li>
                            <li class="me-1">
                                <button  :class="table ? 'active' : ''"
                                        class="custom-btn custom-btn-outline custom-btn-xs"
                                        title="Switch to table view" @click="setTable()">
                                    <font-awesome-icon icon="table"/>
                                    <span v-if="columns"  class="d-none d-lg-inline-block">Switch to Table View</span>
                                    <span class="d-lg-none d-inline-block">Table View</span>
                                </button>
                            </li>
<!--                          <li  class="me-1">-->
<!--                            <button class="custom-btn custom-btn-outline custom-btn-primary custom-btn-xs"-->
<!--                                    @click="setMaps()"-->
<!--                                    title="View Map">-->
<!--                              <font-awesome-icon icon="map"/>-->
<!--                              Maps-->
<!--                            </button>-->
<!--                          </li>-->
                            <li class="me-1">
                                <button class="custom-btn custom-btn-outline custom-btn-success custom-btn-xs"
                                        @click="fireExportCsv()"
                                        title="Download responses in a CSV document">
                                    <font-awesome-icon icon="arrow-down"/>
                                    CSV
                                </button>
                            </li>
                            <li>
                                <button :disabled="columns"
                                        class="custom-btn custom-btn-outline custom-btn-secondary custom-btn-xs"
                                        @click="firePrint()"
                                        :title="table ? 'Download responses in a PDF document' : 'Switch to table view to enable PDF download'">
                                    <font-awesome-icon icon="arrow-down"/>
                                    PDF
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--No responses-->
            <div v-if="!wait && survey.respondents_count == 0" class="posts-empty">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img v-if="!survey.published" loading="lazy" src="@/assets/img/text-box.png" width="80"
                             height="80" alt="Empty box"/>
                        <img v-else loading="lazy" src="@/assets/img/empty-inbox.png" width="80" height="80"
                             alt="Empty box"/>
                    </div>
                    <div v-if="!survey.published">
                        <h4>Your survey has not been published.</h4>
                        <h6>Looks like your survey is still a draft.
                            <router-link class="font-weight-semibold"
                                         :to="`/profile/surveys/view/${this.code}/questionnaire`">Continue editing
                            </router-link>
                        </h6>
                    </div>
                    <div v-else>
                        <h4>There are no responses to show.</h4>
                        <h6>Did you know you can
                            <router-link class="font-weight-semibold" :to="`/profile/surveys/view/${this.code}/share`">
                                share
                            </router-link>
                            your survey to reach a wider audience?
                        </h6>
                    </div>
                </div>
            </div>
            <!--responses-->
            <div id="responses" class="mb-2">
                <div class="responses">
                </div>
            </div>
            <div v-if="survey" class="responses">
                <SurveyRespondentsColumn v-if="columns" ref="column" :code="code" :survey="survey" :response_code="response_code"/>
                <SurveyRespondentsTable v-if="table" ref="table" :code="code" :survey="survey"/>
<!--                <SurveyRespondentsMaps v-if="maps" :code="code"/>-->
            </div>
        </div>
    </div>
</template>

<script>
import SurveyRespondentsColumn from "@/components/Views/Profile/Survey/SurveyRespondentsColumn";
import SurveyRespondentsTable from "@/components/Views/Profile/Survey/SurveyRespondentsTable";
// import SurveyRespondentsMaps from "@/components/Views/Profile/Survey/SurveyRespondentsMaps";

export default {
    name: "SurveyRespondents",
    components: {SurveyRespondentsColumn, SurveyRespondentsTable},
    props: ['code'],
    data() {
        return {
            survey: {},
            stats: {
                views: 0,
                responses: 0,
                questions: 0,
            },

            response_code: null,
            columns: true,
            table: false,
            maps: false,
            tableBtn: true,
            columnBtn: true,
            status: '',
            filter_search: '',
        }
    },
    mounted() {
        if (this.$route.query.columns) {
            this.setColumns()
            if (this.$route.query.code) {
                this.response_code = this.$route.query.code
            }
        }

        this.$http.get(`api/profile/survey/${this.code}/stats`).then(res => {
            this.stats = res.data.data
        })

        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
        }).catch(error => {
            this.showError(error)
        });

    },
    methods: {
        fireExportCsv() {
            this.$refs.table.exportCsv()
        },

        firePrint() {
            let w = window.open()
            w.document.write(this.$refs.table.$el.innerHTML)
            w.document.close()
            w.setTimeout(function () {
                w.print()
                w.window.close()
            }, 1000)
        },
        submitSearch() {
            this.$refs.table.loadRespondents()
        },
        setColumns() {
            this.columns = true
            this.table = false
            this.maps = false
        },
        setTable() {
            this.table = true
            this.columns = false
            this.maps = false
        },
        setMaps() {
            this.columns = false
            this.table = false
            this.maps = true
      }
    }
}
</script>
<style scoped>
</style>
