<template>
    <!-- Responses content - table -->
    <div>
        <Alerts :_success="success" :_error="error"></Alerts>
        <!--Filters-->
        <div v-if="respondents.length !== 0" class="row g-2 px-4 mt-4">
            <div class="mb-1 mb-md-3 col-lg-4">
                <b-form-input
                    v-model="filter_search"
                    class="form-control"
                    placeholder="Search by respondent or code"
                    type="search"
                    autofocus
                    @change="submitQuestionSearch()"
                />
            </div>
            <div class="mb-3 mb-md-3 col-lg-4">
                <b-select class="form-select" v-model="status">
                    <option value="">-- Filter by status --</option>
                    <option value="approved">Approved</option>
                    <option value="flagged">Flagged</option>
                    <option value="declined">Declined</option>
                </b-select>
            </div>
        </div>

        <div class="responses-content d-block card" id="printResponses">
            <b-table-simple
                responsive
                striped
                class="responses-table">
                <b-thead>
                    <b-tr class="bg-white">
                        <b-th class="bg-white hide-on-print">
                            <input type="checkbox" v-model="selectAll">
                        </b-th>
                        <b-th class="bg-white">Date</b-th>
                        <b-th class="bg-white">Code</b-th>
                        <b-th class="bg-white">GPS</b-th>
                        <b-th class="bg-white">Respondent</b-th>
                        <b-th class="bg-white">Status</b-th>
                        <b-th class="bg-white">Lang</b-th>
                        <b-th class="bg-white" v-for="(a, index) in respondent.json" :key="index" :title="a.question">
                            {{ a.question }}
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="res in respondents.data" :key="res.id">
                        <b-td class="hide-on-print"><input type="checkbox" v-model="selected"
                                                           :value="res.id" number></b-td>
                        <b-td>{{ moment(res.created_at).format("DD/MM/YYYY, HH:mm A") }}</b-td>
                        <b-td>{{ res.code }}</b-td>
                        <b-td>
                            <template v-if="res.geo"><span>{{ res.geo }}</span></template>
                            <template v-else><span class="danger">Disabled</span></template>
                        </b-td>
                        <b-td>{{ res.user.name }}</b-td>
                        <b-td class="text-capitalize font-weight-semibold" :class="`status-${res.status}`">
                            {{ res.status }}
                        </b-td>
                        <b-td class="text-uppercase">{{ res.lang }}</b-td>
                        <b-td v-for="(a, index) in res.json" :key="index">
                            <template v-if="a.type == 'checkbox'">
                                {{ a.answer == true ? 'Yes' : 'No' }}
                            </template>
                            <template v-if="a.other">
                                {{ a.answer }} - {{ a.other }}
                            </template>
                            <template v-if="a.additional_info && !Array.isArray(a.additional_info)">
                                {{ a.answer }} ->
                                {{ a.additional_info }}
                            </template>
                            <template v-else class="d-block">
                                {{ a.answer }}
                                <span v-for="x in a.additional_info" :key="x.key"> ->{{ x.value }}</span>
                            </template>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div>
                <pagination
                    :limit="1"
                    :data="respondents"
                    @pagination-change-page="loadRespondents">
                </pagination>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Alerts from "../../Helpers/Alerts"

export default {
    name: "SurveyRespondentsTable",
    components: {Alerts},
    props: ['code'],
    data() {
        return {
            survey: {},
            respondents: {data: []},
            respondent: {json: []},

            length: 5,
            response: [],
            selected: [],
            allSelected: false,

            columns: true,
            table: false,
            maps: false,
            hover: true,
            isFlagged: false,
            error: '',
            sortBy: '',

            selected_res: '',
            status: '',
            filter_search: '',
            filter_respondent: '',
            questions: ''
        }
    },
    watch: {
        selected_res() {
            this.respondent = this.selected_res
        },
        status() {
            this.loadRespondents()
        },
        filter_respondent() {
            this.loadRespondents()
        },
        filter_search() {
            this.loadRespondents()
        }
    },
    mounted() {
        this.loadRespondents()
    },
    computed: {
        selectAll: {
            get: function () {
                return this.respondents ? this.selected.length == this.respondents.length : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.respondents.data.forEach(function (res) {
                        selected.push(res.id);
                    });
                }
                this.selected = selected;
            }
        }
    },
    methods: {
        exportCsv() {
            this.clear()
            this.success = "Exporting responses please wait..."
            let config = {
                responseType: 'blob',
                params: {
                    selected: this.selected
                },
            }

            this.$http.get(`/api/export/csv/${this.code}`, config).then(response => {
                const blob = new Blob([response.data], {type: 'text/csv'})
                const link = document.createElement('a')

                link.href = URL.createObjectURL(blob)
                link.download = this.survey.title + ' ' + ' Respondents'
                link.click()
                URL.revokeObjectURL(link.href)
                this.success = "Done."
            }).catch(error => {
                this.showError(error)
            })
        },
        exportPdf() {
            let config = {
                responseType: 'blob',
                params: {
                    selected: this.selected
                },
            }
            this.$http.get(`/api/export/csv/${this.code}`, config).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },

        loadRespondents(page = 1) {
            this.wait = true
            this.clear()
            this.success = "Loading respondents data, please wait..."
            this.$http.get(`/api/profile/survey/${this.code}/respondents?status=` + this.status + '&search=' + this.filter_search + '&page=' + page).then(response => {
                this.respondents = response.data.data
                this.respondent = response.data.data.data[0]
                this.success = ''
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },

        unflag() {
            this.clear()
            this.$http.post(`/api/response/${this.respondent.id}/flag?reason=`).then(() => {
                this.loadRespondents()
                this.$toaster.success('Response unflagged')
            }).catch(error => {
                this.showError(error)
            })
        },
        flag() {
            let reason = prompt(`Provide reason why you are flagging response from ${this.respondent.user.name}`, '');
            this.$http.post(`/api/response/${this.respondent.id}/flag?reason=${reason}`).then(response => {
                this.respondent = response.data.data
            }).catch(error => {
                this.showError(error)
            })
        },
        undecline() {
            this.$http.post(`/api/response/${this.respondent.id}/decline`).then(() => {
                this.loadRespondents()
                //this.success = 'Response accepted successfully'
                this.$toaster.success('Response approved')
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
                this.success = ''
            })
        },
        actOnResponse(action) {
            if (confirm(`Do you want to ${action} this response`)) {
                this.$http.post(`/api/response/${this.respondent.id}/${action}`).then(response => {
                    this.loadRespondents()
                    this.respondent = response.data.data
                }).catch(error => {
                    this.showError(error)
                })
            }
        },
        sorting() {
            if (this.respondents > 0) {
                return this.respondents.sort((a, b) => a.index > b.index ? 1 : -1)
            } else {
                return false
            }
        },
        deleteFromList: function (res) {
            this.respondents.splice(this.res.indexOf(res), 1);
            console.log('delete')
            alert('hello')
        },
        setRespondent(res) {
            this.respondent = res
        },
        getResponses(res) {
            this.respondent = res
        },
        callDate(date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("Do MMMM YYYY");
            }
        },
        showResFilter() {
            this.resFilters = true
        },
        setColumns() {
            this.columns = true
            this.table = false
            this.maps = false
        },
        setTable() {
            this.table = true
            this.columns = false
            this.maps = false
        },
        setMaps() {
            this.columns = false
            this.table = false
            this.maps = true
        },
        async print() {
            await this.$htmlToPaper("printResponses");
        },
    }
}
</script>
<style scoped>
</style>